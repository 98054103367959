:root {
  --blue700: #134d82;
  --blue600: #2b69a0;
  --blue500: #4f8fc7;
  --blue400: #90bfe8;
  --blue300: #c3e0f9;
  --blue200: #efeafc;
  --green700: #17845e;
  --green600: #17a070;
  --green500: #18b472;
  --black: #282c35;
  --black700: #1b3554;
  --black600: #354c68;
  --black500: #4f627b;
  --black400: #828fa2;
  --black300: #b6bcc9;
  --black200: #e9e9f0;
  --success700: #4eaf51;
  --success600: #71bf74;
  --success500: #83c785;
  --success400: #a0d4a1;
  --success300: #b8dfb9;
  --success200: #e6f3e5;
  --red700: #f54336;
  --red600: #f6564a;
  --red500: #f87b72;
  --red400: #f87b72;
  --red300: #f87b72;
  --red200: #f87b72;
  --orange700: #f19e47;
  --orange200: #fdf1e3;
  --brandingLimeGreen: #00ca41;
  --brandingMidGreen: #00a764;
  --brandingBlueGreen: #008983;
  --brandingBlueish: #005bb1;
  --brandingBlue: #0041cd;
  --primaryTextColour: #323338;
  --white: #ffffff;
}
h1 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 40px;
  font-weight: 600;
}
h2 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 32px;
  font-weight: 600;
}
h3 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 32px;
  font-weight: 600;
}
h4 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 24px;
  font-weight: 600;
}
h5 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 24px;
  font-weight: 600;
}
h6 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
}
h7 {
  color: var(--primaryTextColour);
  font-family: "Roboto-Medium", Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.App {
  text-align: center;
}
.navtab {
  color: var(--black600);
}
.navtab.selected {
  color: var(--white);
}
.UserBox {
  border: 12px;
  border-color: red;
  background-color: green;
  background: green;
}
/* The sidebar menu */
.sidenav {
  height: 100%;
  width: 122px;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: var(--black600);
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 80px;
}

.Secondary-side-nav {
  background-color: var(--white);
  height: 100%;
  width: 200px;
  z-index: 0;
  top: 0;
  left: 122px;
  position: fixed;
  border-right: 1px solid;
  border-color: var(--black200);
}

.topNav {
  width: 100%;
  height: 67px;
  background-color: var(--black700);
  position: fixed;
  top: 0;
  margin-bottom: 10px;
  z-index: 1;
  align-items: flex-start;
  display: flex;
}

.App-logo {
  height: 43px;
  width: 40px;
  padding-left: 42px;
  padding-top: 12px;
}

.App-name {
  color: #edf7ff;
  padding-left: 50px;
  top: 0;
  margin-top: 22px;
  font-size: 22px;
  font-weight: 400;
  font-family: Helvetica;
}

/* Style page content */
.main {
  background-color: #fbfbfb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #354c68;
  padding-top: 67px;
  padding-left: 322px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.LargeInputField {
  background-color: #eeeeee;
  color: black;
  border-radius: 5px;
  width: 444px;
  height: 80px;
  font-size: 33px;
  margin-top: 25px;
  border: 0px none;
}

.LargeGreenButton {
  width: 444px;
  height: 80px;
  background-color: #15a669;
  color: var(--white);
  font-size: 33px;
  border-radius: 5px;
  margin-top: 33px;
  border: "0px none";
}
