
.UserInputBox{
    background-color: white; 
    border: 1px solid grey;
    width: 100%; 
    box-shadow: 1px 1px #888888;
    border-radius: 8px;
    padding: 30px 51px 30px 51px;
}
.LocalHeader{
    width: 100%;
    background-color: white;
    border-bottom: 1px solid;
    border-color: var(--black200);
    height: 77px;
    padding-top: 30px;
    padding-bottom: 15px;
    margin-bottom: 28px;
    position: fixed;
    top: 67px;
    left: 322px;
    text-align: left;
    
}

.CompanyName {
    background-color: var(--white);
    border-radius: 8px;
    padding: 14px 35px;
    width: 900px;
    align-items: center;
    display: flex;
    gap: 20px;
    padding-top: 200px;
}