.outerContainer {
  color: white;
  margin-bottom: 24px;
  margin-left: 10px;
  margin-right: 0;
  border-radius: 10px 0px 0px 10px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  height: 57px;
  width: 94px;
  font-size: 14px;
  font-weight: 500;
}

.outerContainer :hover {
  color: #354c68;
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  height: 57px;
  width: 94px;
}

.innerContainer {
  margin: 8px 18px 0px 8px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 0px;
  position: relative;
}
.logo {
  padding: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  stroke: #354c68;
  fill: #354c68;
}
.link {
  margin-top: 11px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}
