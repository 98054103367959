.popup{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 750px;
    background-color: #fff;
    color: black;
    border-radius: 10px;
}

.popup-inner .close-btn{
    position:absolute;
    top:16px;
    right: 16px;
}